import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import FurtherReading from "../components/furtherReading"

const GoingHomemade = () => (
  <Layout>
    <SEO
      title="Going Homemade With Natural Hair Products"
      description="Here's how you can take matters into your own hands and make hair products that do everything you need to get beautiful hair without the unnecessary man-made ingredients"
    />
    <h1>Going Homemade With Natural Hair Products</h1>
    <h2 style={{color:"Orchid"}}>Take matters into your own hands and make hair products that do everything you need to get beautiful hair without the unnecessary man-made ingredients.</h2>

    <p>Before mass produced products, we had to make our own conditioners, shampoos and treatments to take care of our hair. That was a long, long time ago and the reasons for going home made are a little different right now, we’ll go into those a little later on. When it comes to options for natural hair care, we really only have a few options. Buy products, go homemade or don’t use anything at all.</p>
    
    <div style={{ textAlign: "center" }}>
      <img
        src="https://i.imgur.com/7q3BoEY.jpg"
        alt="how to get an afro nahc"
        style={{marginBottom: "5px"}}
      />
      <p style={{fontSize: "0.9rem", textAlign:"left", color:"grey"}}>Photo credit: <a
        href="https://unsplash.com/@jsnbrsc?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText"
        target="_blank"
        rel="noopener noreferrer"
        style={{ textDecoration:"none", color:"inherit" }}
      >John Briscoe</a></p>
    </div>

    <p>Most of us won’t fall into the don’t use anything at all category so it’s down to buy products or don’t buy products. The list of products out there is immense and if you are looking for something new with no guidance, it can literally take you years to find something you like.</p>
    <p>That’s because there are good products, bad products, products that are good for you and products that are bad for you. I added the last two because there are products out there that lots of people love but when you try them, they don’t work for you. It means that they are “good products” but they are “products that don’t work for you”.</p>
    <p>The game of looking for good products that work for you can be tiresome and eventually, lots of people quit for the other option, going homemade. That’s what this post is about after all. So let’s look at the advantages and disadvantages of going homemade. Before that, let’s quickly get something out of the way;</p>

    <h2>Natural Hair Care</h2>
    <p>Just to clarify something, “natural hair care” can mean two different things and there are no standards so it’s easy to get mixed up.</p>
    <p>One means (and this is the way we mostly use it) to care for untreated hair. The natural refers to the hair not the products or anything surrounding it. You can use products that contain man-made ingredients if you want to. We are not against anyone altering their hair in any way and we write guides and discuss how to care for hair in any state.</p>
    <p>The other way to use the term, and I’m sure you can guess, is a situation where everything is natural and contains no man-made products. Including your hair and the products you use.</p>

    <h2>The Benefits of Going Homemade</h2>
    <p>If you have to make a hair product up from natural ingredients, you have to <strong>understand</strong> what you are putting in. If you don’t, you might end up putting in 2 different ingredients that do the same thing or putting in something that clashes with something else and makes the whole thing look bad.</p>
    <p>Just by studying other recipes to make your own and reading about what each ingredient does, you will <strong>learn about how each nutrient affects your hair</strong>. When all the theory is done, you will get first-hand experience of how it makes a difference to your own hair. Which to me is the most valuable thing of all, it means that you don’t have to be so dependent on the words of others because you have confidence in your own research.</p>
    <p>Making your own products doesn’t mean you have to stop using bought products. Another one of the benefits that is linked to understanding is that you can figure out and <strong>avoid all of the unnecessary additives</strong>. A lot of these additives are for appearance, to increase shelf life, to change the texture and many other reasons. You can decide if you even need them or not and make the choice to keep using them or stop.</p>
    <p>The last thing I want to mention is that it’s <strong>easier than you might think</strong>. Start by copying other recipes if you don’t know where to start then make tweaks until you graduate to coming up with your own ideas.</p>
    <p>You are essentially mixing some things you bought into a bowl, jarring it up if you really want to then using it on your hair. Just like with anything in life, if you do something for long enough, with enough effort, you will become very good at it. And it’s a very <strong>useful skill to get good at</strong>.</p>

    <h2>The Disadvantages of Going Homemade</h2>
    <p>If I’ve painted a picture of making your own products being straight forward and something anyone can pick up and be able to do perfectly in less than a week then I’m sorry because that isn’t the case. Making your own homemade conditioners etc is something that will <strong>take time to figure out</strong>, which brings me to my first point.</p>
    <p><strong>Trial and error</strong> is something you will have to go through. Even if your first mix somehow comes out perfect and does exactly what it’s supposed to do, the second time you try might not bring the same results. You might have added too little of ingredient A or it’s just the wrong time of the year for the mix to work with the temperature outside.</p>
    <p>With <strong>practise</strong> comes experience and that’s what you need to succeed. Just like a lot of us can cook without measuring ingredients, you’ll be able to do the same thing with homemade products eventually, if that’s what your goal is.</p>
    <p>Following on from trial and error is <strong>waste</strong>. There isn’t much you can do with something intended for your hair other than use it for your hair. As raw ingredients, we can probably eat most of them. Once you mix them together though, they become this thing that has to work for its intended purpose. Luckily, the mixes can be adjusted by just adding more of a certain product so it’s extremely unlikely that you will have to throw a whole mix away but it can happen.</p>
    <p>The last few things I want to mention without putting you off are; the batch size is quite hard to determine as a beginner. If you are making for yourself only, you might end up making too much which can lead to the mix going off. Because you are probably not using preservatives, your mix can be prone to going bad and becoming unusable, leading to waste.</p>
    <p>Finally, <strong>sourcing raw ingredients</strong> can be difficult. Most things can be found where you buy your food for your house. If not, you might have to go to a specialist store or order online.</p>

    <h2>What to do now?</h2>
    <p>Hopefully you have everything you need to decide if you want to make your own home-made hair products or not. The choice is up to you so don’t let anyone make you feel bad for the choice you make.</p>
    <p>If you decide you keep using manufactured products and are interested in the ingredients, I would suggest you read up on each one to see exactly what they do. Just like I mentioned already, some are for nutrition purposes, others are for aesthetics, some make the product take longer to go off. Just because it isn’t nutritious doesn’t necessarily mean it is  bad for you. Some of the ingredients sit on your hair and aren’t absorbed by us.</p>
    <p>If you want to make your own products then there are so many resources out there. We have a guide that takes you through all the popular ingredients you will come across, what nutrients they contain and the benefits of those nutrients. Learn about it from us or do your own research as we had to do.</p>
    <p>There are also plenty of communities, influencers and educational videos where you can go to discuss different recipes or watch and take in everything you need to know to make your own products.</p>
    <p>I would also recommend learning the difference between all the product types to be clear on what each one is supposed to do. A deep conditioner is different to a conditioner which is different to a leave-in conditioner. Knowing the differences will help you figure out which ingredients to use.</p>
    
    {/* <h2>Subtitle</h2> */}

    {/* <a
        href="https://example.com/"
        target="_blank"
        rel="noopener noreferrer"
        style={{ textDecoration: "none" }}
      >
        Example Link - for affiliate, add nofollow
      </a> */}



      <FurtherReading
      content={
        <div>
        <p>
            <Link to="/afro-hair-products/" style={{ textDecoration: "none", color:"Orchid", fontWeight:"600" }}>
             The Best Afro Hair Products
            </Link>{" "}
            - The complete list of products you need for every step in your hair care routine...
          </p>  
        <p>
            <Link to="/14-tips-on-how-to-grow-afro-hair/" style={{ textDecoration: "none", color:"Orchid", fontWeight:"600" }}>
            14 tips on how to grow afro hair
            </Link>{" "}
            - Growing afro hair can be one of the most annoying things in the world, the good news is that growing natural doesn't have to be hard. Read our 14 tips to make your life easier here...
          </p>                  
        </div>
      }
    />

    <Link to="/" style={{ textDecoration: "none" }}>
      Go back to the homepage
    </Link>
  </Layout>
)

export default GoingHomemade
